import React from "react"
import { graphql } from "gatsby"
import SimplePage from "@/templates/simplepage"

export default ({ data }) => {
  return (
    <SimplePage data={data} />
  )
}
  
export const pageQuery = graphql`
  query {
    markdownRemark(fileAbsolutePath: {regex: "/datenschutz.md$/"}) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`
